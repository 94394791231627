import { defineStore } from 'pinia';
import { SnackbarContent } from '~/models/general/SnackbarContent';
import { SnackbarType } from '~/models/general/snackbarType';

export const useSnackbarStore = defineStore("snackbar",  {
    state: () => ({
      open: false,
      snackbarContent: [] as SnackbarContent[],
    }),
    actions: {
      openSuccessSnackbar(text: string) {
        const content = new SnackbarContent(text, SnackbarType.SUCCESS)
        this.snackbarContent.push(content);
        if (!this.open) {
          this.open = true
        }
      },
      openErrorSnackbar(text: string) {
        const content = new SnackbarContent(text, SnackbarType.ERROR)
        this.snackbarContent.push(content);
        if (!this.open) {
          this.open = true
        }
      }
    },
})

