import type { SnackbarType } from "./snackbarType"

export class SnackbarContent {
    text: string;
    type: SnackbarType;

    constructor(text: string, type: SnackbarType) {
        this.text = text,
        this.type = type
    }
}